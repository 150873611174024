import React,  { useState } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundWrapper = styled.article`
    grid-area: auto / 1 / auto / -1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: .5rem;

    header{
        grid-area: 1 / 2 / auto / -1;
        
        h1{
          margin-left: 0;
          margin-right: 0;
          margin-top: 0;
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;
          color: inherit;
          font-family: "Nimbus Sans L", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
            Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
          font-weight: 400;
          text-transform: uppercase;
          text-rendering: optimizeLegibility;
          font-size: 1.5rem;
          line-height: 1.1;
        }
    }
    section {
        grid-area: 2 / 2 / auto / -1;
        p{
            text-indent: calc( calc(100% + .5rem) / 8);
            margin: 0 0 1rem 0;
        }
        > div{
            display: grid;
            grid-template-columns: repeat(6,1fr);
            grid-column-gap: .5rem;
            align-items: center;
            margin: 2rem 0;
            
            > div{
                grid-column: span 2;
            }
        }
    }

    a {
        position: relative;
    }
    a span::after {
        content: '';
        height: 2px;
        width: 0%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #1a1a1a; 
        transition: 0.25s ease width; 
    }
    a:hover span::after {
        width: 100%;
    }

    @media (min-width: 45em){
        grid-column-gap: 1rem;

        section{
            grid-area: 2 / 2 / auto / 8;
            p{
                text-indent: calc( calc(100% + 1rem) / 6);
                margin: 0;
            }
            > div{
                grid-column-gap: 1rem;
            }
        }
    }
    @media (min-width: 60em){
        section{

            > div{
                
                > div{
                    grid-column: span 1;
                }
            }
        }
    }
`;

const NotFoundPage = ({ location }) => {
  // Declare a new state variable, which we'll call "count"
  const [annotation, setAnnotation] = useState(false);
  const [annotationAlert, setAnnotationAlert] = useState(false);
  
  return(
  <Layout location={location} annotation={annotation} setAnnotation={setAnnotation} annotationAlert={annotationAlert} setAnnotationAlert={setAnnotationAlert}>
    <SEO title="404: Not found" />
    <NotFoundWrapper>
      <header>
      <h1>NOT FOUND</h1>
      </header>
      <section>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </section>
    </NotFoundWrapper>
  </Layout>
)}

export default NotFoundPage
